import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDcPJ3s1bn0Ah4G2VUwJ_uwhkWAuTtbjXU",
  authDomain: "trade-vortex.firebaseapp.com",
  projectId: "trade-vortex",
  storageBucket: "trade-vortex.appspot.com",
  messagingSenderId: "73069464424",
  appId: "1:73069464424:web:35faab506d5fdc407fe4dd",
};

// const firebaseConfig = {
//   apiKey: "AIzaSyDtmNsjSnruKS6ZCs3jwqec0QMqGqGKQWk",
//   authDomain: "finance-v2-e1682.firebaseapp.com",
//   projectId: "finance-v2-e1682",
//   storageBucket: "finance-v2-e1682.appspot.com",
//   messagingSenderId: "133386766551",
//   appId: "1:133386766551:web:e0236afa4a4dc881e20892",
// };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
